<template>
    <div class="view-root flex-column">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p class="common-title">门票列表</p>
            <p class="btn-wrap-width" @click="editTicket(null)">新增门票</p>
        </div>
        <div class="table-margin-top flex-1-overflow-y">
            <div class="table-header table-margin-bottom">
                <div class="table-index">序号</div>
                <div class="table-name">门票名称</div>
                <div class="table-type">门票种类</div>
                <div class="table-price">门票价格</div>
                <div class="table-number">门票数量</div>
                <div class="table-operation">操作</div>
            </div>
            <div v-for="(ticket, index) in dataList" :key="index" class="table-item table-margin-bottom">
                <div class="table-index">{{ (index + 1) }}</div>
                <div class="table-name" style="font-weight: bold;">{{ ticket.name }}</div>
                <div class="table-type" style="font-weight: bold;">{{ ticket.type }}</div>
                <div class="table-price">{{ ticket.price }}</div>
                <div class="table-number">{{ ticket.number }}</div>
                <div class="table-operation" style="display: flex;">
                    <p class="list-btn-green" style="text-align: center;width: 100px;"
                        @click="doShowCreateNewApplicationDialog(ticket)">
                        新建邀请函</p>

                    <p class="list-btn-blue" style="text-align: left;padding-left: 8px;margin-left: 10px;"
                        @click="editTicket(ticket)">
                        编辑</p>
                    <p class="list-btn-red" style="margin-left: 10px;text-align: left;padding-left: 8px;"
                        @click="doRemoveTicket(ticket)">删除</p>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="showTicketDialog" :title="ticketInfo.id == '' ? '添加门票' : '编辑门票'" width="45%">
            <div>
                <el-form :model="ticketInfo" ref="ticketForm" :rules="rules" label-position="left">
                    <el-form-item label="门票名称" prop="name">
                        <el-input v-model="ticketInfo.name" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="门票种类" prop="type">
                        <el-select v-model="ticketInfo.type" placeholder="请选择">
                            <el-option label="普通门票" value="普通门票"></el-option>
                            <el-option label="VIP门票" value="VIP门票"></el-option>
                            <el-option label="贵宾门票" value="贵宾门票"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="门票价格" prop="price">
                        <el-input-number v-model="ticketInfo.price" placeholder="请输入"></el-input-number> (元)
                    </el-form-item>
                    <el-form-item label="门票数量" prop="number">
                        <el-input-number v-model="ticketInfo.number" placeholder="请输入"></el-input-number> (个)
                    </el-form-item>
                </el-form>
                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showTicketDialog = false">取 消</el-button>
                        <el-button type="primary" @click="saveTicket()">确 定</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="showCreateNewApplicationDialog" title="新建邀请函" width="55%">
            <div>
                <el-form :model="registerInfo" :rules="addAppRules" ref="registerForm">
                    <el-form-item label="被邀请人姓名" prop="user_name">
                        <el-input v-model="registerInfo.user_name" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="被邀请人手机号码" prop="user_mobile">
                        <el-input v-model="registerInfo.user_mobile" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="被邀请人邮箱" prop="user_email">
                        <el-input v-model="registerInfo.user_email"
                            :disabled="special_user_email && special_user_email != ''" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-form>
                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showCreateNewApplicationDialog = false">取 消</el-button>
                        <el-button type="primary" @click="confrimNewApp()">确定</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import '../../assets/common/common.css'
import {
    getTickets,
    removeTicket,
    updateTicket,
    addMeetingApplication
} from '../../api/api'
export default ({
    name: 'index',
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            dataList: [],
            ticketInfo: {
                id: '',
                meeting_id: this.meeting_id,
                name: '',
                type: '',
                price: '',
                number: '',
            },
            rules: {
                name: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: 'Please select', trigger: 'blur' }
                ],
                price: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                number: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
            },

            registerInfo: {
                meeting_id: this.meeting_id,
                ticket_id: '',
                user_mobile: '',
                user_name: '',
                user_email: '',
                open_id: ''
            },
            addAppRules: {
                user_name: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                user_mobile: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                user_email: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
            },
            showTicketDialog: false,
            showCreateNewApplicationDialog: false
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        confrimNewApp() {
            this.$refs.registerForm.validate((val) => {
                if (val) {
                    addMeetingApplication(this.registerInfo).then((res) => {
                        let result = res.data
                        if (result.code == 0) {
                            this.$message.success('新邀请函创建成功')
                            this.showCreateNewApplicationDialog = false
                        } else {
                            this.$message.success(result.msg)
                        }
                    })
                }
            })
        },

        doShowCreateNewApplicationDialog(row) {
            this.registerInfo = {
                meeting_id: this.meeting_id,
                ticket_id: row._id.$id,
                user_mobile: '',
                user_name: '',
                user_email: '',
                open_id: ''
            }
            this.showCreateNewApplicationDialog = true
        },

        fetchData() {
            getTickets(this.meeting_id).then((res) => {
                this.dataList = res.data.data
            })
        },
        doRemoveTicket(row) {
            this.$alert('你确定要删除此类门票吗？', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        removeTicket(row._id.$id).then((res) => {
                            this.$message.success('删除成功')
                            this.fetchData()
                        })
                    }
                }
            })
        },
        editTicket(row) {
            if (row) {
                this.ticketInfo = {
                    id: row._id.$id,
                    meeting_id: row.meeting_id,
                    name: row.name,
                    type: row.type,
                    price: row.price,
                    number: row.number,
                }
            } else {
                this.ticketInfo = {
                    id: '',
                    meeting_id: this.meeting_id,
                    name: '',
                    type: '',
                    price: '',
                    number: '',
                }
            }
            this.showTicketDialog = true
        },
        saveTicket() {
            this.$refs.ticketForm.validate((val) => {
                if (val) {
                    updateTicket(this.ticketInfo).then((res) => {
                        this.$message.success('保存成功')
                        this.fetchData()
                        this.showTicketDialog = false
                    })
                }
            })
        }
    }
})
</script>
<style scoped lang="scss">
.table-name {
    flex: 2;
}

.table-type {
    flex: 2;
}

.table-price {
    flex: 2;
}

.table-number {
    flex: 2;
}

.table-operation {
    flex: 3;
}
</style>